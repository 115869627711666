.bg-grey {
  background-color: #6d8387;
}

.section-title {
  .unscoped-offers-link {
    text-decoration: underline;
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      color: black;
      opacity: 0.75;
    }
  }
}

.react-select-container {
  font-size: 14px;
}

// fixed bootstrap.min.css styles (was unset variable)
.modal, .modal-content {
  --bs-modal-color: rgb(33, 37, 41)
}

.modal-with-sticky {
  .modal-dialog {
    .react-select-container {
      z-index: 2;
    }
  }
}

.edit-notes-modal {
  .textarea-field {
    height: 200px !important;
    resize: none;
  }

  .modal-footer {
    button {
      margin-left: 10px;
      margin-right: 10px;
    }

    button:disabled {
      opacity: 0.35;
    }
  }
}

button, .btn {
  // TODO: check properties to unset
  &:focus-visible {
    box-shadow: none;
    outline: none;
  }
}

.main-content-area .login-area .logo-text {
  font-weight: 700;
  font-size: 36px;
  color: var(--headingColor);
  line-height: 1;
}

:root {
  --border-sticky-width: 1px;
  --border-sticky-color: rgb(249, 249, 249);
  --border-sticky: var(--border-sticky-width) solid var(--border-sticky-color);
}

@mixin sticky($zIndex: 1) {
  position: sticky;
  z-index: $zIndex;
  background-color: #fff;
}

.modal-dialog {
  .modal-content {
    .modal-header--sticky {
      @include sticky(3);
      top: 0;
    }

    .modal-footer--sticky {
      @include sticky(1);
      bottom: 0;
    }
  }
}

.global-table-area {
  .white-space-wrap {
    white-space: wrap
  }

  // TODO: check the styles for table--sticky-header in tables
  .table-sticky-wrapper {
    overflow: unset !important; // remove this if max-height used for wrapper

    .table {
      border-collapse: separate;
      border-spacing: var(--border-sticky-width);

      thead {
        position: sticky;
        top: var(--border-sticky-width);
      }

      th, td {
        box-shadow: 0 0 0 var(--border-sticky-width) var(--border-sticky-color);
        border: none; // unset previous styles
      }
    }

    &--top-60 {
      .table thead {
        top: 60px; // check offset for different case (in modal - title height, for example ~60px now)
      }
    }
  }

  // END of table-sticky-wrapper
}

.global-table-area .table tbody tr td {
  .remix-icon {
    svg {
      fill: var(--mainColor);
    }

    &:hover {
      svg {
        fill: var(--whiteColor);
      }
    }

    &.more-text-button {
      &:hover {
        svg {
          fill: var(--dangerColor);
        }
      }
    }
  }
}