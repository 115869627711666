.table-sort-label {
  font-style: inherit;
  cursor: pointer;
  transition: var(--transition);

  svg {
    margin-left: 20px;
  }

  &:hover {
    opacity: 0.5;
  }
}