.filters-button {
  cursor: pointer;
  user-select: none;
  opacity: 1;
  transition: var(--transition);

  svg {
    width: 30px;
    height: 30px;
  }

  .count {
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    background-color: rgba(93, 135, 255, .2);
    color: var(--mainColor);
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: var(--borderRadius);
    transform: translateY(-50%);
    margin-left: 0;
  }

  &:hover {
    opacity: 0.75;
  }
}

.filters {
  position: relative;

  .filters-panel {
    display: none;

    &.active {
      display: block;
    }
  }

  .filter-input-cont {
    margin-top: 12px;
    margin-left: 20px;

    .form-label {
      color: rgb(140, 144, 151);
    }

    .form-control {
      height: 38px;
      padding-left: 12px !important;
      padding-right: 65px !important; // for IconSearch and IconCross space-size
      transition: var(--transition);
      //TODO: check the styles" are they necessary or change via 'react-select' props
      //border-color: hsl(0, 0%, 80%);
      //border-radius: 4px;
      //border-style: solid;
      //border-width: 1px;

      &:focus {
        //border-width: 2px;

        & ~ .input-buttons .icon-cross {
          color: hsl(0, 0%, 40%);
        }
      }
    }

    .input-buttons {
      display: flex;
      align-self: stretch;
      align-items: center;

      right: 2px;

      i {
        padding: 6px;

        color: hsl(0, 0%, 80%);
        transition: var(--transition);

        &:hover {
          color: hsl(0, 0%, 40%);
        }
      }

      .icon-cross {
        cursor: pointer;

        svg {
          display: inline-block;
          fill: currentColor;
          line-height: 1;
          stroke: currentColor;
          stroke-width: 0;
        }
      }

      .indicator-separator {
        display: inline-block;
        align-self: stretch;
        width: 1px;
        background-color: hsl(0, 0%, 80%);
        margin-bottom: 8px;
        margin-top: 8px;
        box-sizing: border-box;
        font-size: 14px;
      }
    }
  }

  .filters-items-cont {
    width: 100%;

    .filters-button-cont {
      flex-direction: row;
    }
  }

  .filters-button-cont {
    display: flex;
    flex-direction: column; // to use this style if 'filters-button-cont' is NOT in filters-items-cont
    align-items: flex-end;

    button {
      display: inline-flex !important;
      margin-top: 20px;
      margin-left: 20px;
    }

    .clear-filter-button {
      height: 41px;
      margin-top: 17px;
    }
  }

  .clear-filter-button {
    svg {
      fill: currentColor;
    }
  }
}

.filters-offer-modal {
  margin-top: -15px;
}